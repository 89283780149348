//Standard
import { mapState } from 'vuex';

// Component
import ScreenHeader from '@/components/ScreenHeader/ScreenHeader.vue';
import ProjectDeadline from '@/components/ProjectDeadline/ProjectDeadline.vue';
import BottomButton from '@/components/BottomButton/BottomButton.vue';
import SaveButtonArea from '@/components/SaveButtonArea/SaveButtonArea.vue';
import NotificationModal from '@/components/NotificationModal/NotificationModal.vue';
import socketClient from '@/services/SOCKET.js';
import HearingService from '@/services/API/hearings.service.js';
import ScheduleService from '@/services/API/schedule.service';
// Constant
import CommonConst from '@/constants/CommonConst';
import HearingConst from '@/constants/HearingConst';

import { sendNotify, toLineMessageTemplate } from '@/utilities';
import autoHeightTextArea from '@/mixin/autoHeightTextArea';
import redDotNotifyMixin from '../../../mixin/redDotNotifyMixin.js';

import moment from 'moment';

const SELECTIVE_CONTENT = '「 ヒアリング 」を承認しますか？';
const PLAIN_NOTI_CONTENTS = '「 スケジュール 」に進みます';

export default {
	name: 'HearingPage',
	components: {
		ScreenHeader,
		ProjectDeadline,
		SaveButtonArea,
		NotificationModal,
		BottomButton,
	},
	mixins: [autoHeightTextArea, redDotNotifyMixin],
	props: {
		mobileView: Boolean,
		mobileLiveScreenMinimum: Boolean,
	},
	data() {
		return {
			middleContent: ' 様 ／ ',
			screenIcon: 'hearing-icon',
			screenName: 'キックオフミーティング',
			availableWhenClick: false,
			availableValidate: false,
			hearingContents: '',
			hearingDetails: {},
			selectiveContents: '',
			plainContents: '',
			nextProcess: 'decision',
			isTextareaDisabled: false,
			isButtonShowed: true,
			plainModalId: 'hearing-plain-modal',
			selectiveModalId: 'hearing-selective-modal',
			hearingStatus: '',
			showBottomButton: false,
			mailContent: '',
			mailTitle: '',
			subject: '',
			isSendCreator: false,
			placeholderContent: HearingConst.PLACEHOLDER_CONTENT,

			// Show main content after fetch data from server successfully
			isShowContent: false,
		};
	},

	computed: {
		// Get data from store
		...mapState([
			'projectId',
			'userId',
			'projectInfo',
			'clientInfo',
			'creatorInfo',
			'managementMasterInfo',
			'schedule',
			'preview',
			'appLogo',
			'appLogoPlain',
			'clientMode',
			'redDotIndexs',
		]),
		mailTemplate() {
			return {
				destinations: this.isSendCreator
					? [this.creatorInfo['email_address']]
					: [this.clientInfo['email_address']],
				sender: this.managementMasterInfo['sender_email_address'],
				subject: this.subject,
				template: this.isSendCreator ? 'processmail_creator' : 'processmail_client',
				template_params: {
					projectName: this.projectInfo['project_name'],
					projectId: this.projectId,
					screenName: this.screenName,
					clientName: this.clientInfo['client_name'],
					clientId: this.clientInfo['id'],
					emailContent: this.mailContent,
					sendMailDate: moment().format('LLL'),
					appLogo: this.appLogo,
					appLogoFooter: this.appLogoPlain,
					processingScreenUrl: this.isSendCreator
						? `${this.projectInfo['process_creator_url']}hearing`
						: `${this.projectInfo['process_client_url']}hearing`,
					clientRegisterParams:
						`register_name=${this.clientInfo['client_name']}&` +
						`email_address=${this.clientInfo['email_address']}&phone_number=${this.clientInfo['phone_number']}` +
						`&account_type=client&client_id=${this.clientInfo['id']}&responsible_name=${this.clientInfo['responsible_name']}`,
				},
			};
		},
		lineMessage() {
			return toLineMessageTemplate({
				title: `[CoMoDe]［${this.clientInfo['client_name']}様 / ${this.projectInfo['project_name']}］`,
				message: this.mailContent,
				currentUrl: this.isSendCreator
					? `${this.projectInfo['process_creator_url']}hearing`
					: `${this.projectInfo['process_client_url']}hearing`,
			});
		},
		notifyContent() {
			return this.projectInfo['notify_method'] == 0
				? this.mailTemplate
				: this.projectInfo['notify_method'] == 1
				? this.lineMessage
				: {
						lineContent: this.lineMessage,
						mailContent: this.mailTemplate,
				  }; // eslint-disable-line no-mixed-spaces-and-tabs
		},
	},

	watch: {
		/**
		 * Update notification red-dot base on screen status
		 * @param {String} newStatus  Screen Status
		 */
		hearingStatus(newStatus) {
			if (newStatus === CommonConst.SCREEN_STATUS.WAITING_CONFIRM) {
				this.setRedDotNotiy(CommonConst.SCREEN_ID.HEARING);
			} else {
				// Use redDotNotifyMixin for removing
				this.removeRedDotNotifyWithSocket(CommonConst.SCREEN_ID.HEARING);
			}
		},

		// isShowContent() {
		// 	if (this.mobileView)
		// 		this.setAutoHeightTextArea(
		// 			'hearing-Textarea',
		// 			HearingConst.TEXT_AREA_MIN_HEIGHT,
		// 			true
		// 		);
		// },

		// hearingContents() {
		// 	if (this.mobileView)
		// 		this.setAutoHeightTextArea(
		// 			'hearing-Textarea',
		// 			HearingConst.TEXT_AREA_MIN_HEIGHT,
		// 			false
		// 		);
		// },
	},

	methods: {
		//TO DO: Wraping related functions
		onConfirm() {
			this.$bvModal.show(`${this.selectiveModalId}`);
			this.selectiveContents = SELECTIVE_CONTENT;
		},

		onShowBottomButton(status) {
			if (status === CommonConst.SCREEN_STATUS.WAITING_CONFIRM) {
				this.showBottomButton = true;
			}
		},

		async onAcceptConfirm() {
			this.$store.commit('setIsAppProcessing', true);

			this.plainContents = PLAIN_NOTI_CONTENTS;
			let status = CommonConst.SCREEN_STATUS.ACCEPT_CONFIRM;
			await this._updateHearings(this.projectId, status);
			this.showBottomButton = false;
			this.hearingStatus = status;

			// Send event
			socketClient.send('data_transfer', {
				data: {
					user_id: this.userId,
					event_name: 'hearing_accept_confirm',
				},
				room: this.projectId,
			});

			// send mail case 9
			this.mailTitle = `[CoMoDe]［${this.clientInfo['client_name']}様 / ${this.projectInfo['project_name']}］「ヒアリング」確定`;
			this.subject = `【CoMoDe】[${this.clientInfo['client_name']}/${this.projectInfo['project_name']}]「ヒアリング」確定`;
			this.mailContent = `「ヒアリング」を\n確定しました。`;
			this.isSendCreator = true;
			sendNotify(this.notifyContent, this.projectInfo['notify_method'], [
				this.projectInfo['responsible_user'],
			]);
			this.mailTitle = `[CoMoDe] ${this.projectInfo['project_name']}「ヒアリング」確定`;
			this.subject = `【CoMoDe】[${this.projectInfo['project_name']}]「ヒアリング」確定`;
			this.mailContent = `「ヒアリング」を\n確定しました。`;
			this.isSendCreator = false;
			sendNotify(this.notifyContent, this.projectInfo['notify_method'], [
				this.projectInfo['client_id'],
			]);

			this.$store.commit('setIsAppProcessing', false);
		},

		async onDenyConfirm() {
			this.$store.commit('setIsAppProcessing', true);

			let status = CommonConst.SCREEN_STATUS.DENY_CONFIRM;
			await this._updateHearings(this.projectId, status);
			this.showBottomButton = false;
			this.hearingStatus = status;

			socketClient.send('data_transfer', {
				data: {
					user_id: this.userId,
					event_name: 'hearing_deny_confirm',
				},
				room: this.projectId,
			});

			// send mail case 9
			this.mailTitle = `[CoMoDe]［${this.clientInfo['client_name']}様 / ${this.projectInfo['project_name']}］「ヒアリング」が確定されませんでした`;
			this.subject = `【CoMoDe】[${this.clientInfo['client_name']}/${this.projectInfo['project_name']}]「ヒアリング」が確定されませんでした`;
			this.mailContent = `「ヒアリング」が\n確定されませんでした。\n改めて内容を擦り合わせましょう。`;
			this.isSendCreator = true;
			sendNotify(this.notifyContent, this.projectInfo['notify_method'], [
				this.projectInfo['responsible_user'],
			]);
			this.mailTitle = `[CoMoDe] ${this.projectInfo['project_name']}「ヒアリング」を確定しませんでした`;
			this.subject = `【CoMoDe】[${this.projectInfo['project_name']}]「ヒアリング」を確定しませんでした`;
			this.mailContent = `「ヒアリング」を\n確定しませんでした。\n改めて内容を擦り合わせましょう。`;
			this.isSendCreator = false;
			sendNotify(this.notifyContent, this.projectInfo['notify_method'], [
				this.projectInfo['client_id'],
			]);

			this.$store.commit('setIsAppProcessing', false);
		},

		/**
		 * Get hearing contents displayed in text box
		 * @param {Integer} projectId project identifier
		 * @returns {Object} Hearing details
		 */
		async _getHearings(projectId) {
			let response = await HearingService.get(projectId);
			console.log(response.data);
			if (response && response.status === 200) {
				return response.data;
			} else {
				// TODO: Error notification
				console.log('Get hearing details failed');
			}
			return;
		},

		/**
		 * Create hearing detail
		 * @param {Object} content hearing detail
		 */
		async _createHearings(content) {
			try {
				let response = await HearingService.post({ doc_content: content });

				if (!response || response.status !== 200) {
					throw 'Create Requiremnt failed';
				}
				console.log('%c Create Hearing successfully', 'color: green');
			} catch (error) {
				console.log(`%c ${error}`, 'color: red');
			}
		},

		/**
		 *
		 * @param {Integer} projectId project id
		 * @param {String} status hearing status
		 */
		async _updateHearings(projectId, status) {
			try {
				let response = await HearingService.update(projectId, {
					status: status,
				});
				if (!response || response.status !== 200) {
					throw 'Update Requiremnt failed';
				}
				console.log('%c Update Hearing successfully', 'color: green');
			} catch (error) {
				console.log(`%c ${error}`, 'color: red');
			}
		},

		/**
		 * Check textbox contents and confirm flag
		 * If confirm flag equal 1 -> Disable textbox and hide save(保存) button
		 */
		async _checkInitialState() {
			let hearingDetails = await this._getHearings(this.projectId);
			this.hearingDetails = hearingDetails;

			if (hearingDetails) {
				this.hearingContents = hearingDetails.decision;
				this.hearingStatus = hearingDetails.status;
				this.onShowBottomButton(hearingDetails.status);
			}
		},
		async getScheduleInfo(projectId) {
			let response = await ScheduleService.get(projectId);
			if (response && response.status === 200) {
				console.log('Get schedule successfully');
				let data = response.data;
				this.scheduleInfo = data;
				this.$store.commit('setSchedule', data);
			} else {
				console.log('Get schedule failed');
			}
		},
	},

	async created() {
		await socketClient.listen('new_data_transfer', async (data) => {
			switch (data?.event_name) {
				case 'hearing_confirm_required':
					this.showBottomButton = true;
					this.hearingStatus = CommonConst.SCREEN_STATUS.WAITING_CONFIRM;
					break;

				case 'hearing_edit':
					this.hearingContents = data.content;
					break;

				case 'direct_to_next_page': {
					this.$router.push(this.nextProcess);
					const confirmInfo = {
						screenId: CommonConst.SCREEN_ID.HEARING,
						confirmInfo: true,
					};
					this.$emit('on-send-confirm-info', confirmInfo);
					return true;
				}
				case 'send_client_mode': {
					if (this.clientMode) {
						this.hearingContents = data.content;
					}
					break;
				}

				case 'client-remove-reddot':
					if (!!this.preview || this.clientMode)
						this.removeRedDotNotify(CommonConst.SCREEN_ID.HEARING);
					break;

				default:
					break;
			}
		});
	},

	async mounted() {
		this.$store.commit('setIsAppProcessing', true);
		await this._checkInitialState();
		moment.locale('ja');
		this.isShowContent = true;
		await this.getScheduleInfo(this.projectId);
		this.$store.commit('setIsAppProcessing', false);

		if (this.clientMode) {
			window.setTimeout(() => {
				socketClient.send('data_transfer', {
					data: {
						user_id: this.userId,
						event_name: 'client_mode_on',
					},
					room: this.projectId,
				});
			}, 3000);
		}
	},
};
